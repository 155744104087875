import { Component } from '@angular/core';

@Component({
    selector: 'app-welcome.component',
    templateUrl: 'welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
    
}
