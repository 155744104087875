import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { PedidoPopIdSeq } from '../models/classes/pedidoPopIdSeq';
import { BASE_URL } from '../tokens/baseUrl.token';

@Injectable({
  providedIn: 'root'
})
export class PedidoPopIdSeqService extends BaseService<PedidoPopIdSeq> {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, `${baseUrl}/PedidoPopIdSeq`); 
  }
}






