import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Fornecedor } from 'src/shared/models/classes/fornecedor';
import { Result } from 'src/shared/models/classes/result';
import { DialogService } from 'src/shared/services/dialog.service';
import { FornecedorService } from 'src/shared/services/fornecedor.service';
import { ToastService } from 'src/shared/services/toast.service';
import { columnsFornecedorGrid } from 'src/shared/utils/commonGridColumns';
import { hasValue, toArray, toRecord } from 'src/shared/utils/commonValueChecksConversions';
import { ErrorNotification, InfoNotification, WarningNotification } from 'src/shared/utils/notification-types';

@Component({
  selector: 'app-consulta-fornecedor',
  imports: [CommonModule, MatIconModule, MatDialogModule, FormsModule, MatFormFieldModule],
  templateUrl: './consulta-fornecedor.component.html',
  styleUrl: './consulta-fornecedor.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsultaFornecedorComponent {
  fornecedorPesquisado: Fornecedor | null | undefined = new Fornecedor();
  filtrosFornecedor: Fornecedor = new Fornecedor();
  columnsFornecedorGrid = columnsFornecedorGrid;

  constructor(
    public dialog: MatDialog,
    private fornecedorService: FornecedorService,
    private toastService: ToastService,
    private dialogService: DialogService
  ) { }

  consultarFornecedor() {
    if (!hasValue(this.filtrosFornecedor.fornecedorId) &&
      !hasValue(this.filtrosFornecedor.mf) &&
      !hasValue(this.filtrosFornecedor.nome)) {
      this.toastService.open(new WarningNotification("Preencha um dos filtros para realizar a pesquisa (ID / MF / NOME)"));
      return;
    }

    this.fornecedorService.search(toRecord(this.filtrosFornecedor), false, false).subscribe({
      next: (result: Result<Fornecedor>) => {
        var totalRecords = result.totalRecords ?? 0;
        if (totalRecords == 0) {
          this.toastService.open(new InfoNotification("Não foram encontrado(s) fornecedor(es) com os filtros informados"));
          return;
        }

        if (totalRecords == 1) {
          this.fornecedorPesquisado = (result.content as Array<Fornecedor>)[0];
          this.filtrosFornecedor.mf = this.fornecedorPesquisado.mf;
          this.filtrosFornecedor.fornecedorId = this.fornecedorPesquisado.fornecedorId;
          this.filtrosFornecedor.nome = this.fornecedorPesquisado.nome;
          return;
        }

        if (totalRecords > 1) {
          this.dialogService.openDialogSelecao<Fornecedor>({
            data: toArray<Fornecedor>(result.content),
            gridColumns: columnsFornecedorGrid,
            titulo: 'Seleção de Fornecedor',
            subtitulo: `Multiplos Resultados encontrados, selecione um para visualizar os detalhes`,
            selecaoUnica: true,
            pageSize: result.totalRecords
          }).subscribe(result => {
            if (result) {
              this.fornecedorPesquisado = result;
              this.filtrosFornecedor.mf = this.fornecedorPesquisado.mf;
              this.filtrosFornecedor.fornecedorId = this.fornecedorPesquisado.fornecedorId;
              this.filtrosFornecedor.nome = this.fornecedorPesquisado.nome;
            }
          });
        }    
      },
      error: (err: Result<Fornecedor>) => {
        if(err.status == 404){
          this.toastService.open(new InfoNotification(`Fornecedor Não Encontrado`));
        }
        if (err.hasErrors) {
          this.toastService.open(new ErrorNotification(`Ocorreu um ou mais erros durante a pesquisa: ${err.messages?.join("\r\n")}`));
        }
      }
    });
  }

  limparTela() {
    this.filtrosFornecedor = new Fornecedor();
    this.fornecedorPesquisado = new Fornecedor();
  }
}
