import { Component } from '@angular/core';

@Component({
  selector: 'app-cadastro-manutencao-pedido',
  imports: [],
  templateUrl: './cadastro-manutencao-pedido.component.html',
  styleUrl: './cadastro-manutencao-pedido.component.scss'
})
export class CadastroManutencaoPedidoComponent {

}
