export class Notification {
  constructor(public message: string, public actionLabel?: string, public action?: () => void) {}
}

export class SuccessNotification extends Notification {
  type = 'success';
}
export class ErrorNotification extends Notification {
  type = 'error';
}
export class InfoNotification extends Notification {
  type = 'info';
}
export class WarningNotification extends Notification {
  type = 'warning';
}
