import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { toQueryString } from '../utils/query-string';
import { BASE_URL } from '../tokens/baseUrl.token';
import { Result } from '../models/classes/result';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class BaseService<T> {

  constructor(protected http: HttpClient, @Inject(BASE_URL) protected baseUrl: string) {

  }

  getAll(page?: number, pageSize?: number, includeDetails: boolean = false, pageEvent?: PageEvent): Observable<Result<T>> {
    if(pageEvent){
      page = pageEvent.pageIndex;
      pageSize = pageEvent.pageSize;
    }
    const params = { page, pageSize, includeDetails };
    const queryString = toQueryString(params);
    return this.http.get<Result<T>>(`${this.baseUrl}${queryString}`);
  }

  search(
    filters: Record<string, unknown>,
    includeDetails: boolean = false,
    orFilterCriteria: boolean = false
  ): Observable<Result<T>> {
    const params = { ...filters, includeDetails, orFilterCriteria };
    const queryString = toQueryString(params);
    return this.http.get<Result<T>>(`${this.baseUrl}/search${queryString}`);
  }

  getById(id: number, filters?: Record<string, unknown>, includeDetails: boolean = false): Observable<T> {
    const params = { filters, includeDetails };
    const queryString = toQueryString(params);
    return this.http.get<T>(`${this.baseUrl}/${id}${queryString}`);
  }

  create(data: T): Observable<Result<T>> {
    return this.http.post<Result<T>>(this.baseUrl, data);
  }

  update(id: number, data: T): Observable<Result<T>> {
    return this.http.put<Result<T>>(`${this.baseUrl}/${id}`, data);
  }

  delete(id: number): Observable<Result<T>> {
    return this.http.delete<Result<T>>(`${this.baseUrl}/${id}`);
  }
}
