import { ChangeDetectorRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BuscaModalComponent } from '../components/busca-modal/busca-modal.component';
import { SelecaoModalComponent } from '../components/selecao-modal/selecao-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}
  
  openDialogBusca<T>(config: {
    gridColumns: any[],
    inputsBusca: any[],
    titulo: string,
    subtitulo: string,
    service: any,
    selecaoUnica?: boolean,
    page?: number,
    pageSize?: number,
    cardStyle?: string,
    width?: string
  }): Observable<T | null> {
    const dialogRef = this.dialog.open(BuscaModalComponent<T>, {
      width: config.width ?? '450',
      data: {
        gridColumns: config.gridColumns,
        inputsBusca: config.inputsBusca,
        titulo: config.titulo,
        subtitulo: config.subtitulo,
        service: config.service,
        selecaoUnica: config.selecaoUnica ?? true,
        page: config.page ?? 0,
        pageSize: config.pageSize ?? 10,
        cardStyle: config.cardStyle ?? ''
      },
      disableClose: false
    });

    return dialogRef.afterClosed();
  }

  openDialogSelecao<T>(config: {
    data: any[]
    gridColumns: any[],
    titulo: string,
    subtitulo: string,
    page?: number,
    pageSize?: number,
    width?: string,
    selecaoUnica?: boolean
  }): Observable<T | null> {
    const dialogRef = this.dialog.open(SelecaoModalComponent<T>, {
      width: config.width ?? '450',
      data: {
        data: config.data,
        gridColumns: config.gridColumns,
        titulo: config.titulo,
        subtitulo: config.subtitulo,
        page: config.page ?? 0,
        pageSize: config.pageSize ?? 10,
        selecaoUnica: config.selecaoUnica
      },
      disableClose: false
    });
    return dialogRef.afterClosed();
  }
}
