import { TiposInputBusca } from '../../enums/tiposInputBusca.enum';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, InjectionToken, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AbacoGridComponent } from '../abaco-grid/abaco-grid.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGridColumn } from 'src/shared/models/interfaces/IgridColumn';
import { IGridTemplate } from 'src/shared/models/interfaces/IgridTemplate';
import { IInputBusca } from 'src/shared/models/interfaces/IInputBusca';
import { BaseService } from 'src/shared/services/base.service';
import { ToastService } from 'src/shared/services/toast.service';
import { ErrorNotification, InfoNotification, WarningNotification } from 'src/shared/utils/notification-types';
import { Result } from 'src/shared/models/classes/result';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { NgIconsModule } from '@ng-icons/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { Observable, tap } from 'rxjs';


@Component({
  selector: 'app-busca-modal',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgIconsModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatButtonModule,
    MatDividerModule,
    AbacoGridComponent,
    MatIconModule,
    MatPaginatorModule
  ],
  templateUrl: './busca-modal.component.html',
  styleUrl: './busca-modal.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class BuscaModalComponent<T> implements OnInit {
  @ViewChild(AbacoGridComponent) gridComponent!: AbacoGridComponent<any>;
  titulo!: string;
  subtitulo!: string;
  inputsBusca: IInputBusca[] = [];
  gridColumns: IGridColumn[] = [];
  gridTemplates: IGridTemplate[] = [];
  filterSelectAllFunction!: Function;
  searchForm!: FormGroup;
  submitted = false;
  tiposInputBusca = TiposInputBusca; // Expose enum to the template
  data: T[] = [];
  page!: number;
  pageSize!: number;
  service!: BaseService<T>;
  paginationFunction!: any;
  totalItens: number = 0;
  selecaoUnica!: boolean;
  cardStyle: string = 'card-no-max-width';

  constructor(
    public dialogRef: MatDialogRef<BuscaModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public componentData: any,
    private fb: FormBuilder,
    private toastService: ToastService,
    private injector: Injector) {
    this.inputsBusca = componentData?.inputsBusca;
    this.titulo = componentData?.titulo;
    this.subtitulo = componentData?.subtitulo;
    this.gridColumns = componentData?.gridColumns;
    this.gridTemplates = componentData?.gridTemplates;
    this.filterSelectAllFunction = componentData?.filterSelectAllFunction;
    this.service = this.injector.get(componentData?.service as InjectionToken<BaseService<T>>);
    this.paginationFunction = this.service.getAll;
    this.selecaoUnica = componentData?.selecaoUnica;
    this.page = componentData?.page;
    this.pageSize = componentData?.pageSize;
    if(componentData.cardStyle){
      this.cardStyle = componentData?.cardStyle;
    }
  }

  ngOnInit(): void {
    this.createFormControls();
  }

  private createFormControls() {
    const controls: { [key: string]: FormControl } = {};
    this.inputsBusca.forEach(input => {
      controls[input.ngModel] = new FormControl('', Validators.required);
    });
    this.searchForm = new FormGroup(controls);
  }

  paginateFunction(pageIndex: number, pageSize: number): Observable<Result<T>> {
    return this.service.getAll(pageIndex, pageSize);
  }

  fetchData = (pageIndex: number, pageSize: number): Observable<Result<T>> => {
    this.data = [];
    this.gridComponent.dataSource.data = [...[]];
    this.page = pageIndex - 1;
    this.pageSize = pageSize;
    if (this.page <= 0) {
      this.page = 1;
    }
    if (this.anyFieldFilled()) {
      return this.service.search(this.searchForm.value, false, false).pipe(
        tap((result: Result<T>) => {
          this.totalItens = result.totalRecords ?? 0;
          if (Array.isArray(result.content)) {
            this.data.push(...result.content);
          } else if (result.content !== null && result.content !== undefined) {
            this.data.push(result.content);
          }
        })
      );
    }
    else {
      return this.service.getAll(pageIndex, pageSize, false).pipe(
        tap((result: Result<T>) => {
          this.totalItens = result.totalRecords ?? 0;
          if (Array.isArray(result.content)) {
            this.data.push(...result.content);
          } else if (result.content !== null && result.content !== undefined) {
            this.data.push(result.content);
          }
        })
      );

    }
  }

  search() {
    if (this.page <= 0) {
      this.page = 1;
    }
    this.data = [];
    this.gridComponent.dataSource.data = [...[]];
    if (this.anyFieldFilled()) {
      this.service.search(this.searchForm.value, false, false).subscribe({
        next: (response: Result<T>) => {
          this.totalItens = response.totalRecords ?? 0;
          if (Array.isArray(response.content)) {
            this.data.push(...response.content);
          } else if (response.content !== null && response.content !== undefined) {
            this.data.push(response.content);
          }
          this.gridComponent.updatePaginatorAndData(this.totalItens, 0, 0, this.data);
        },
        error: (errorResponse) => {
          if (errorResponse.status == 404) {
            this.toastService.open(
              new WarningNotification(`Não Foram encontrados dados com os filtros informados`, "Fechar")
            );
          }
          else {
            this.toastService.open(
              new ErrorNotification(`Erro ao buscar: ${errorResponse?.messages?.join(", ") || "Erro desconhecido"}`, "Fechar")
            );
          }
        }
      });
    }
    else {
      this.service.getAll(this.page, this.pageSize, false).subscribe({
        next: (response: Result<T>) => {
          this.totalItens = response.totalRecords ?? 0;
          if (Array.isArray(response.content)) {
            this.data.push(...response.content);
          } else if (response.content !== null && response.content !== undefined) {
            this.data.push(response.content);
          }
          this.gridComponent.updatePaginatorAndData(this.totalItens, response.page, response.pageSize, this.data);
        },
        error: (errorResponse) => {
          if (errorResponse.status == 404) {
            this.toastService.open(
              new WarningNotification(`Não Foram encontrados dados com os filtros informados`, "Fechar")
            );
          }
          else {
            this.toastService.open(
              new ErrorNotification(`Erro ao buscar: ${errorResponse?.messages?.join(", ") || "Erro desconhecido"}`, "Fechar")
            );
          }
        }
      });
    }
  }

  anyFieldFilled() {
    const formValues = this.searchForm.value;
    return Object.values(formValues).some(value => value !== '');
  }

  sendToParent() {
    if (this.selecaoUnica) {
      return this.gridComponent.selection.selected.length > 1
        ? this.toastService.open(new InfoNotification("Selecione apenas um dos itens para prosseguir"))
        : this.dialogRef.close(this.gridComponent.selection.selected[0]);
    }
    this.dialogRef.close(this.gridComponent.selection.selected);
  }

  close() {
    this.dialogRef.close();
  }

}
