import { BaseModel } from "./baseModel";
import { ControleCopiaPedidoSeq } from "./controleCopiaPedidoSeq";
import { Fornecedor } from "./fornecedor";
import { LinhaPedido } from "./linhaPedido";

export class Pedido extends BaseModel {
  pedidoId?: number;
  fornecedorId?: number;
  mF?: number;
  dataEmissao?: Date | null;
  cpCondicao?: number;
  requisitante?: string;
  das?: string;
  situacao?: string;
  identificacao?: string;
  respGeracao?: string;
  dataGeracao?: Date | null;
  dataVersao?: Date | null;
  fornecedor?: Fornecedor;
  controleCopiaPedidoSeq?: ControleCopiaPedidoSeq;
  linhasPedido?: LinhaPedido[];

  constructor(
    pedidoId?: number,
    fornecedorId?: number,
    mF?: number,
    dataEmissao?: Date | null,
    cpCondicao?: number,
    requisitante?: string,
    das?: string,
    situacao?: string,
    identificacao?: string,
    respGeracao?: string,
    dataGeracao?: Date | null,
    dataVersao?: Date | null
  ) {
    super(); 
    this.pedidoId = pedidoId;
    this.fornecedorId = fornecedorId;
    this.mF = mF;
    this.dataEmissao = dataEmissao;
    this.cpCondicao = cpCondicao;
    this.requisitante = requisitante;
    this.das = das;
    this.situacao = situacao;
    this.identificacao = identificacao;
    this.respGeracao = respGeracao;
    this.dataGeracao = dataGeracao;
    this.dataVersao = dataVersao;
  }
}
