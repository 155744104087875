import { Routes } from '@angular/router';
import { ConsultaPedidoComponent } from './pedidos/consulta-pedido/consulta-pedido.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CopiaPedidoComponent } from './pedidos/copia-pedido/copia-pedido.component';
import { CadastroManutencaoPedidoComponent } from './pedidos/cadastro-manutencao-pedido/cadastro-manutencao-pedido.component';
import { ConsultaFornecedorComponent } from './fornecedores/consulta-fornecedor/consulta-fornecedor.component';
import { CadastroManutencaoFornecedorComponent } from './fornecedores/cadastro-manutencao-fornecedor/cadastro-manutencao-fornecedor.component';


export const App_Routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent, pathMatch: 'full' },

  { path: 'consulta-pedido', component: ConsultaPedidoComponent, pathMatch: 'full' },
  { path: 'cadastro-manutencao-pedido', component: CadastroManutencaoPedidoComponent, pathMatch: 'full' },
  { path: 'copia-pedido', component: CopiaPedidoComponent, pathMatch: 'full' },

  { path: 'consulta-fornecedor', component: ConsultaFornecedorComponent, pathMatch: 'full' },
  { path: 'cadastro-manutencao-fornecedor', component: CadastroManutencaoFornecedorComponent, pathMatch: 'full' },

  
]

export class AppRoutingModule { }
