import { PedidoPopIdSeq } from './../../../shared/models/classes/pedidoPopIdSeq';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Fornecedor } from 'src/shared/models/classes/fornecedor';
import { Pedido } from 'src/shared/models/classes/pedido';
import { TooltipPosition } from '@angular/material/tooltip';
import { FornecedorService } from 'src/shared/services/fornecedor.service';
import { PedidoPopIdSeqService } from 'src/shared/services/pedidoPopIdSeq.service';
import { columnsFornecedorGrid, columnsPedidoGrid } from 'src/shared/utils/commonGridColumns';
import { inputsBuscaFornecedor, inputsBuscaPedido } from 'src/shared/utils/commonBuscaInputs';
import { Observable, tap } from 'rxjs';
import { Result } from 'src/shared/models/classes/result';
import { AbacoGridComponent } from 'src/shared/components/abaco-grid/abaco-grid.component';
import { DialogService } from 'src/shared/services/dialog.service';

@Component({
  selector: 'app-consulta-pedido',
  templateUrl: './consulta-pedido.component.html',
  styleUrls: ['./consulta-pedido.component.scss'],
  imports: [MatIconModule, MatDialogModule, FormsModule, MatFormFieldModule, CommonModule,
    AbacoGridComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ConsultaPedidoComponent {
  @ViewChild(AbacoGridComponent) gridComponent!: AbacoGridComponent<any>;
  pedidos: PedidoPopIdSeq[] = [];
  page!: number;
  pageSize!: number;
  pedidoForm!: FormGroup;
  columnsPedidoGrid = columnsPedidoGrid;
  pedidoSequenciado: Pedido = new Pedido();
  alertsEnabled!: any;
  totalItens: number = 0;
  fornecedorSelecionado: Fornecedor = new Fornecedor();
  pedidoSelecionado: Pedido = new Pedido();
  ediTooltipDirection: TooltipPosition = 'after';

  constructor(
    public dialog: MatDialog,
    private pedidoPopIdSeqService: PedidoPopIdSeqService,
    private dialogService: DialogService
  ) { }

  openDialogFornecedor() {
    this.dialogService.openDialogBusca<Fornecedor>({
      gridColumns: columnsFornecedorGrid,
      inputsBusca: inputsBuscaFornecedor,
      titulo: 'Fornecedor',
      subtitulo: 'Busca de Fornecedor',
      service: FornecedorService
    }).subscribe((result: any) => {
      if (result) {
        this.fornecedorSelecionado = result;
      }
    });

  }

  openDialogPedido() {
    this.dialogService.openDialogBusca<PedidoPopIdSeq>({
      gridColumns: columnsPedidoGrid,
      inputsBusca: inputsBuscaPedido,
      titulo: 'Cópia de Pedido Sequenciado',
      subtitulo: 'Buscar Pedidos Sequenciados',
      service: PedidoPopIdSeqService,
      cardStyle: 'card-min-content',
      width: '600'
    }).subscribe(result => {
      if (result) {
        this.pedidoSelecionado = result;
      }
    });
  }

  anyFieldFilled() {
    const formValues = this.pedidoForm.value;
    return Object.values(formValues).some(value => value !== '');
  }

  fetchData = (pageIndex: number, pageSize: number): Observable<Result<PedidoPopIdSeq>> => {
    this.pedidos = [];
    this.gridComponent.dataSource.data = [...[]];
    this.page = pageIndex - 1;
    this.pageSize = pageSize;
    if (this.page <= 0) {
      this.page = 1;
    }
    if (this.anyFieldFilled()) {
      return this.pedidoPopIdSeqService.search(this.pedidoForm.value, false, false).pipe(
        tap((result: Result<PedidoPopIdSeq>) => {
          this.totalItens = result.totalRecords ?? 0;
          if (Array.isArray(result.content)) {
            this.pedidos.push(...result.content);
          } else if (result.content !== null && result.content !== undefined) {
            this.pedidos.push(result.content);
          }
        })
      );
    }
    else {
      return this.pedidoPopIdSeqService.getAll(pageIndex, pageSize, false).pipe(
        tap((result: Result<PedidoPopIdSeq>) => {
          this.totalItens = result.totalRecords ?? 0;
          if (Array.isArray(result.content)) {
            this.pedidos.push(...result.content);
          } else if (result.content !== null && result.content !== undefined) {
            this.pedidos.push(result.content);
          }
        })
      );

    }
  }
}
