import { BaseModel } from "./baseModel";
import { ComposicaoPedidoPopIdSeq } from "./composicaoPedidoPopIdSeq";
import { ControleCopiaPedidoSeq } from "./controleCopiaPedidoSeq";
import { Pedido } from "./pedido";
import { PedidoPopIdSeq } from "./pedidoPopIdSeq";

export class Fornecedor extends BaseModel {
    fornecedorId?: number | null;
    mf?: number | null;
    cnpj?: string | null;
    nome?: string | null;
    razaoSocial?: string | null;
    cgc?: number | null;
    companyMasterNo?: number | null;
    mercExterno?: string | null;
    ediStatus?: string | null;
    ediIdentificacao?: string | null;
    ediCompactador?: string | null;
    dataInclusao?: Date | null;
    dataAlteracao?: Date | null;
    dataExclusao?: Date | null;
    dataRevisao?: Date | null;
    usuario?: string | null;
    respGeracao?: string | null;
    supplierNoMatris?: string | null;
    famCod?: string | null;
    tipoPessoa?: string | null;
    ediTipoCompac?: string | null;
    pedidos?: Pedido[] | null; 
    composicaoPopidSeqs?: ComposicaoPedidoPopIdSeq[] | null; 
    pedidoPopIdSeqs?: PedidoPopIdSeq[] | null; 
    controleCopiaPedidoSeq?: ControleCopiaPedidoSeq | null; 

    constructor(
        fornecedorId?: number | null,
        mF?: number | null,
        cnpj?: string | null,
        nome?: string | null,
        razaoSocial?: string | null,
        cGC?: number | null,
        companyMasterNo?: number | null,
        mercExterno?: string | null,
        ediStatus?: string | null,
        ediIdentificacao?: string | null,
        ediCompactador?: string | null,
        dataInclusao?: Date | null,
        dataAlteracao?: Date | null,
        dataExclusao?: Date | null,
        dataRevisao?: Date | null,
        usuario?: string | null,
        respGeracao?: string | null,
        supplierNoMatris?: string | null,
        famCod?: string | null,
        tipoPessoa?: string | null,
        ediTipoCompac?: string | null
    ) {
        super();
        this.fornecedorId = fornecedorId ?? null;
        this.mf = mF ?? null;
        this.cnpj = cnpj ?? null;
        this.nome = nome ?? null;
        this.razaoSocial = razaoSocial ?? null;
        this.cgc = cGC ?? null;
        this.companyMasterNo = companyMasterNo ?? null;
        this.mercExterno = mercExterno ?? null;
        this.ediStatus = ediStatus ?? null;
        this.ediIdentificacao = ediIdentificacao ?? null;
        this.ediCompactador = ediCompactador ?? null;
        this.dataInclusao = dataInclusao ?? null;
        this.dataAlteracao = dataAlteracao ?? null;
        this.dataExclusao = dataExclusao ?? null;
        this.dataRevisao = dataRevisao ?? null;
        this.usuario = usuario ?? null;
        this.respGeracao = respGeracao ?? null;
        this.supplierNoMatris = supplierNoMatris ?? null;
        this.famCod = famCod ?? null;
        this.tipoPessoa = tipoPessoa ?? null;
        this.ediTipoCompac = ediTipoCompac ?? null;
        this.pedidos = [];
        this.composicaoPopidSeqs = [];
        this.pedidoPopIdSeqs = [];
        this.controleCopiaPedidoSeq = null;
    }
}