<div class="tds-container">
    <div class="tds-row">
        <div class="tds-col-xs-12
            tds-col-sm-12
            tds-col-md-12
            tds-col-lg-12
            tds-col-xlg-12
            tds-col-xxlg-12
            tds-col-max-12">
            <tds-card header="Pedidos" subheader="Lista de Pedidos" body-divider="true">
                <div slot="body" class="card-no-max-width">
                    <div class="tds-row">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3">
                            <tds-text-field [value]="fornecedorSelecionado.nome" ngDefaultControl size="md"
                                label-position="inside" placeholder="Fornecedor">
                                <span slot="tds-label">Fornecedor</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-hide-xs
                                    tds-hide-sm
                                    tds-hide-md
                                    tds-hide-lg
                                    tds-show-xlg
                                    tds-show-xxlg
                                    tds-show-max
                                    tds-col-xlg-1
                                    tds-col-xxlg-1
                                    tds-col-max-1
                                    tds-u-pt3
                                    ">
                            <tds-button (click)="this.openDialogFornecedor()" variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-show-xs
                        tds-show-sm
                        tds-show-md
                        tds-show-lg
                        tds-hide-xlg
                        tds-hide-xxlg
                        tds-hide-max
                        tds-u-mt3
                        tds-u-mb3
                        ">
                            <tds-button (click)="this.openDialogFornecedor()" fullbleed variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3">
                            <tds-text-field [value]="pedidoSequenciado.id" ngDefaultControl size="md"
                                label-position="inside" placeholder="Pedido">
                                <span slot="tds-label">Pedidos</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-hide-xs
                                    tds-hide-sm
                                    tds-hide-md
                                    tds-hide-lg
                                    tds-show-xlg
                                    tds-show-xxlg
                                    tds-show-max
                                    tds-col-xlg-1
                                    tds-col-xxlg-1
                                    tds-col-max-1
                                    tds-u-pt3
                                    ">
                            <tds-button (click)="this.openDialogPedido()" variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-show-xs
                                    tds-show-sm
                                    tds-show-md
                                    tds-show-lg
                                    tds-hide-xlg
                                    tds-hide-xxlg
                                    tds-hide-max
                                    tds-u-mt3
                                    tds-u-mb3
                        ">
                            <tds-button (click)="this.openDialogPedido()" fullbleed variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-12
                        tds-col-xxlg-12
                        tds-col-max-12">
                            <app-abaco-grid [data]="this.pedidos" [columns]="this.columnsPedidoGrid"
                                [enableSelectAll]="true" [gridAlignment]="'left'" [filterModalPaginator]="true"
                                [paginationFunction]="fetchData" [totalItens]="this.totalItens"
                                [pageSize]="this.pageSize" [page]="this.page"></app-abaco-grid>
                        </div>
                    </div>
                </div>
            </tds-card>
        </div>
    </div>
</div>