<div class="tds-container">
    <div class="tds-row">
        <div class="tds-col-xs-12
            tds-col-sm-12
            tds-col-md-12
            tds-col-lg-12
            tds-col-xlg-12
            tds-col-xxlg-12
            tds-col-max-12">
            <tds-card header="Fornecedores" subheader="Consulta de Fornecedores" body-divider="true">
                <div slot="body" class="card-no-max-width">
                    <div class="tds-row">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3
                                    tds-u-mt2">
                            <tds-text-field [(ngModel)]="filtrosFornecedor.fornecedorId" ngDefaultControl size="md"
                                label-position="inside" placeholder="ID do Fornecedor">
                                <span slot="tds-label">Id do Fornecedor</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3
                                    tds-u-mt2">
                            <tds-text-field [(ngModel)]="filtrosFornecedor.mf" ngDefaultControl size="md"
                                label-position="inside" placeholder="MF">
                                <span slot="tds-label">MF</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-6
                        tds-col-xxlg-6
                        tds-col-max-6
                        tds-u-mt2">
                            <tds-text-field [(ngModel)]="filtrosFornecedor.nome" ngDefaultControl size="md"
                                label-position="inside" placeholder="Nome">
                                <span slot="tds-label">Nome</span>
                            </tds-text-field>

                        </div>
                    </div>
                    <div class="tds-hide-xs
                    tds-hide-sm
                    tds-hide-md
                    tds-hide-lg
                    tds-show-xlg 
                    tds-show-xxlg 
                    tds-show-max">
                        <div class="tds-row tds-u-justify-self-center">
                            <div class="tds-col-xlg-12
                                        tds-col-xxlg-12
                                        tds-col-max-12
                                        tds-u-mt3">
                                <tds-button (click)="this.consultarFornecedor()" variant="primary" size="sm"
                                    text="Pesquisar">
                                </tds-button>
                                <tds-button (click)="this.limparTela()" variant="secondary" class="tds-u-ml3" size="sm"
                                    text="Limpar">
                                </tds-button>
                            </div>
                        </div>
                    </div>
                    <div class="tds-show-xs
                    tds-show-sm
                    tds-show-md
                    tds-show-lg
                    tds-hide-xlg
                    tds-hide-xxlg
                    tds-hide-max">
                        <div class="tds-row">
                            <div class="tds-col-xs-12
                                        tds-col-sm-12
                                        tds-col-md-12
                                        tds-col-lg-12
                                        tds-u-mt3">
                                <tds-button (click)="this.consultarFornecedor()" variant="primary" fullbleed size="sm"
                                    text="Pesquisar">
                                </tds-button>
                            </div>
                            <div class="tds-col-xs-12
                            tds-col-sm-12
                            tds-col-md-12
                            tds-col-lg-12
                        tds-u-mt3">
                                <tds-button (click)="this.limparTela()" fullbleed variant="secondary" size="sm"
                                    text="Limpar">
                                </tds-button>
                            </div>
                        </div>
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-6
                        tds-col-xxlg-6
                        tds-col-max-6
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.razaoSocial" disabled="true" ngDefaultControl
                                size="md" label-position="inside" placeholder="Razão Social">
                                <span slot="tds-label">Razão Social</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-3
                        tds-col-xxlg-3
                        tds-col-max-3
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.supplierNoMatris" disabled="true"
                                ngDefaultControl size="md" label-position="inside" placeholder="Cod.Matris(Old_ID)">
                                <span slot="tds-label">Cod.Matris(Old_ID)</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-3
                        tds-col-xxlg-3
                        tds-col-max-3
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.companyMasterNo" disabled="true"
                                ngDefaultControl size="md" label-position="inside" placeholder="Cod.Matris(Old_ID)">
                                <span slot="tds-label">Company Master No</span>
                            </tds-text-field>
                        </div>
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-4
                        tds-col-xxlg-4
                        tds-col-max-2
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.mercExterno" disabled="true" ngDefaultControl
                                size="md" label-position="inside" placeholder="Mercado Externo">
                                <span slot="tds-label">Mercado Externo</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-4
                        tds-col-xxlg-4
                        tds-col-max-2
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.cgc" disabled="true" ngDefaultControl
                                size="md" label-position="inside" placeholder="CGC / CNPJ">
                                <span slot="tds-label">CGC / CNPJ</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-4
                        tds-col-xxlg-4
                        tds-col-max-2
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.ediStatus" disabled="true" ngDefaultControl
                                size="md" label-position="inside" placeholder="EDI">
                                <span slot="tds-label">EDI</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-4
                        tds-col-xxlg-4
                        tds-col-max-2
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.ediIdentificacao" disabled="true"
                                ngDefaultControl size="md" label-position="inside" placeholder="CXP">
                                <span slot="tds-label">CXP</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-4
                        tds-col-xxlg-4
                        tds-col-max-2
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.ediCompactador" disabled="true"
                                ngDefaultControl size="md" label-position="inside" placeholder="Comp">
                                <span slot="tds-label">Comp</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-col-xlg-4
                        tds-col-xxlg-4
                        tds-col-max-2
                        tds-u-mt2">
                            <tds-text-field [value]="fornecedorPesquisado?.ediTipoCompac" disabled="true"
                                ngDefaultControl size="md" label-position="inside" placeholder="Tipo">
                                <span slot="tds-label">Tipo</span>
                            </tds-text-field>
                        </div>
                    </div>
                </div>
            </tds-card>
        </div>
    </div>
</div>