import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';

// @ts-ignore
import { addTheme } from '@scania/components';
// @ts-ignore
import { theme as scania } from '@scania/theme-light';
import { defineCustomElements } from '@scania/tegel/loader';
import { AppComponent } from './app/app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { App_Routes } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideIcons } from '@ng-icons/core';
import {
  heroClipboardDocument,
  heroWrench,
  heroMagnifyingGlass,
  heroChartBar,
  heroChevronLeft,
  heroChevronRight,
  heroCalendarDays,
  heroTruck,
  heroRectangleStack,
  heroInformationCircle,
  heroDocumentDuplicate
} from '@ng-icons/heroicons/outline';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './shared/components/abaco-grid/abaco-grid-custom-paginator';

defineCustomElements(window);


addTheme(scania);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    provideRouter(App_Routes),
    FormsModule,
    ReactiveFormsModule,
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi()
    ),
    provideIcons({
      heroChevronLeft,
      heroChevronRight,
      heroClipboardDocument,
      heroWrench,
      heroMagnifyingGlass,
      heroChartBar,
      heroCalendarDays,
      heroTruck,
      heroRectangleStack,
      heroInformationCircle,
      heroDocumentDuplicate
    }),
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl
    },
  ]
})
  .catch(err => console.error(err));
