import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Fornecedor } from 'src/shared/models/classes/fornecedor';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { FornecedorService } from 'src/shared/services/fornecedor.service';
import { MatSelectModule } from '@angular/material/select';
import { PedidoPopIdSeq } from 'src/shared/models/classes/pedidoPopIdSeq';
import { PedidoPopIdSeqService } from 'src/shared/services/pedidoPopIdSeq.service';
import { Pedido } from 'src/shared/models/classes/pedido';
import { columnsFornecedorGrid, columnsPedidoGrid } from 'src/shared/utils/commonGridColumns';
import { inputsBuscaFornecedor, inputsBuscaPedido } from 'src/shared/utils/commonBuscaInputs';
import { DialogService } from 'src/shared/services/dialog.service';

@Component({
  selector: 'app-copia-pedido',
  imports: [
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  templateUrl: './copia-pedido.component.html',
  styleUrl: './copia-pedido.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CopiaPedidoComponent {
  alertsEnabled: any;
  fornecedorAntigoSelecionado: Fornecedor = new Fornecedor();
  fornecedorNovoSelecionado: Fornecedor = new Fornecedor();
  pedidoAntigoSelecionado: Pedido = new Pedido();
  ediTooltipDirection: TooltipPosition = 'after';
  gerarBlocoAgreement!: boolean;

  constructor(
    public dialog: MatDialog,
    private dialogService: DialogService
  ) {

  }

  openDialogFornecedor(fornecedorNovo?: boolean): void {
    this.dialogService.openDialogBusca<Fornecedor>({
      gridColumns: columnsFornecedorGrid,
      inputsBusca: inputsBuscaFornecedor,
      titulo: 'Fornecedor',
      subtitulo: 'Busca de Fornecedor',
      service: FornecedorService
    }).subscribe((result: any) => {
      if (result) {
        if (fornecedorNovo) {
          this.fornecedorNovoSelecionado = result;
        } else {
          this.fornecedorAntigoSelecionado = result;
        }
      }
    });
  }

  openDialogPedido(): void {
    this.dialogService.openDialogBusca<PedidoPopIdSeq>({
      gridColumns: columnsPedidoGrid,
      inputsBusca: inputsBuscaPedido,
      titulo: 'Cópia de Pedido Sequenciado',
      subtitulo: 'Buscar Pedidos Sequenciados',
      service: PedidoPopIdSeqService,
      cardStyle: 'card-min-content'
    }).subscribe(result => {
      if (result) {
        this.pedidoAntigoSelecionado = result;
      }
    });
  }
}
