<div class="home-layout">
  <tds-header>
    <tds-header-hamburger id="home-hamburger" aria-label="Open application menu" aria-haspopup="true"
      aria-expanded="false" (click)="toggleMobileSideMenu()"></tds-header-hamburger>

    <tds-header-title routerLink="welcome">CopSeq</tds-header-title>

    <tds-header-dropdown>
      <span slot="label">Fornecedores</span>
      <tds-header-dropdown-list>
        <tds-header-dropdown-list-item>
          <a routerLink="consulta-fornecedor" routerLinkActive="tds-nav__item--active">Consultar</a>
        </tds-header-dropdown-list-item>
        <tds-header-dropdown-list-item>
          <a routerLink="cadastro-manutencao-fornecedor" routerLinkActive="tds-nav__item--active">Cadastro /
            Manutenção</a>
        </tds-header-dropdown-list-item>
      </tds-header-dropdown-list>
    </tds-header-dropdown>

    <tds-header-dropdown>
      <span slot="label">Pedidos Sequenciados</span>
      <tds-header-dropdown-list>
        <tds-header-dropdown-list-item>
          <a routerLink="consulta-pedido">Consultar</a>
        </tds-header-dropdown-list-item>
        <tds-header-dropdown-list-item>
          <a routerLink="cadastro-manutencao-pedido" routerLinkActive="tds-nav__item--active">Cadastro / Manutenção</a>
        </tds-header-dropdown-list-item>
        <tds-header-dropdown-list-item>
          <a routerLink="copia-pedido" routerLinkActive="tds-nav__item--active">Cópia de Pedido</a>
        </tds-header-dropdown-list-item>
      </tds-header-dropdown-list>
    </tds-header-dropdown>

    <tds-header-dropdown slot="end" placement="end" no-dropdown-icon class="demo-hide demo-lg-show" selected>
      <img slot="icon" src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg" alt="User menu." />
      <tds-header-dropdown-list size="lg">
        <tds-header-dropdown-list-user header="Name Nameson" subheader="Company name"></tds-header-dropdown-list-user>
        <tds-header-dropdown-list-item selected>
          <a href="https://www.scania.com">My Instructions</a>
        </tds-header-dropdown-list-item>
        <tds-header-dropdown-list-item>
          <a href="https://www.scania.com">Task List</a>
        </tds-header-dropdown-list-item>
      </tds-header-dropdown-list>
    </tds-header-dropdown>

    <tds-header-brand-symbol slot="end">
      <a routerLink="welcome" aria-label="Scania - red gryphon on blue shield"></a>
    </tds-header-brand-symbol>
  </tds-header>
  <div class="side-menu-and-main">
    <tds-side-menu id="demo-side-menu" aria-label="Side menu" open="{{ mobileNavOpen }}">
      <tds-side-menu-overlay slot="overlay" (click)="toggleMobileSideMenu()" slot="overlay"></tds-side-menu-overlay>

      <tds-side-menu-close-button slot="close-button" slot="close-button"
        (click)="toggleMobileSideMenu()"></tds-side-menu-close-button>

      <tds-side-menu-dropdown>
        <span slot="label">Fornecedores</span>
        <tds-side-menu-dropdown-list>
          <tds-side-menu-dropdown-list-item>
            <a routerLink="consulta-fornecedor" routerLinkActive="tds-nav__item--active">Consultar</a>
          </tds-side-menu-dropdown-list-item>
          <tds-side-menu-dropdown-list-item>
            <a routerLink="cadastro-manutencao-fornecedor" routerLinkActive="tds-nav__item--active">Cadastro /
              Manutenção</a>
          </tds-side-menu-dropdown-list-item>
        </tds-side-menu-dropdown-list>
      </tds-side-menu-dropdown>

      <tds-side-menu-dropdown>
        <span slot="label">Pedidos</span>
        <tds-side-menu-dropdown-list>
          <tds-side-menu-dropdown-list-item>
            <a routerLink="consulta-pedido" routerLinkActive="tds-nav__item--active">Consulta</a>
          </tds-side-menu-dropdown-list-item>
          <tds-side-menu-dropdown-list-item>
            <a routerLink="cadastro-manutencao-pedido" routerLinkActive="tds-nav__item--active">Cadastro /
              Manutencao</a>
          </tds-side-menu-dropdown-list-item>
          <tds-side-menu-dropdown-list-item>
            <a routerLink="copia-pedido" routerLinkActive="tds-nav__item--active">Cópia de Pedido</a>
          </tds-side-menu-dropdown-list-item>
        </tds-side-menu-dropdown-list>
      </tds-side-menu-dropdown>

      <tds-side-menu-dropdown slot="end" class="demo-lg-hide" selected>
        <tds-side-menu-user slot="label" heading="Name Namesson" subheading="Company name"
          img-src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
          img-alt=""></tds-side-menu-user>
        <tds-side-menu-dropdown-list>
          <tds-side-menu-dropdown-list-item selected>
            <a href="https://design.scania.com">My Instructions</a>
          </tds-side-menu-dropdown-list-item>
          <tds-side-menu-dropdown-list-item>
            <a href="https://design.scania.com">Task List</a>
          </tds-side-menu-dropdown-list-item>
        </tds-side-menu-dropdown-list>
      </tds-side-menu-dropdown>
    </tds-side-menu>
    <main class="home-main main-routes">
      <router-outlet></router-outlet>
    </main>
  </div>
  <tds-footer>
  </tds-footer>
</div>