import { s as store } from './store-79644476.js';

// Polyfill for CustomEvent
(function () {
  if (typeof window.CustomEvent === 'function') return false;
  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: null
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();
const detail = {
  store
};
const event = new CustomEvent('storeReady', {
  detail
});
const fa_icons = {};
const defaultTheme = {
  light: {
    icons: {},
    components: {},
    colors: {}
  }
};
// FIXME: We don't use FA_icons
Object.keys(fa_icons).map(key => {});
window.CorporateUi = Object.assign(Object.assign({}, window.CorporateUi), detail);
const newValue = store.get('theme');
newValue.items['light'] = defaultTheme.light;
store.set('theme', newValue);
document.dispatchEvent(event);
window.CorporateUi.storeReady = true;