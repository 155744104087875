'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTheme = exports.defineCustomElements = undefined;
var _loader = require('../loader');
exports.defineCustomElements = defineCustomElements;
exports.addTheme = addTheme;
function defineCustomElements() {
  fixFouc();
  return (0, _loader.applyPolyfills)().then(function () {
    return (0, _loader.defineCustomElements)();
  });
}
function addTheme(_theme) {
  var _ref = window.CorporateUi || {},
    store = _ref.store,
    storeReady = _ref.storeReady;
  if (storeReady) {
    return init(_theme, {
      detail: {
        store: store
      }
    });
  }

  // TODO: Maybe this event listener should be accesable from the theme itself?
  document.addEventListener('storeReady', function (event) {
    return init(_theme, event);
  });
  function init(theme, event) {
    var store = event.detail.store;
    // need to get store theme through get API to make it work in IE

    var storeTheme = store.get('theme');
    theme['light'].components = document.head.attachShadow ? theme['light'].components.default : theme['light'].components.ie;
    storeTheme.items['light'] = theme['light'];
    store.set('theme', storeTheme);
  }
}
function fixFouc() {
  var elm = document.createElement('style');
  var style = document.createTextNode('body { visibility: hidden; }');
  document.head.insertBefore(elm, document.head.firstChild);
  elm.appendChild(style);
  document.onreadystatechange = function () {
    if (document.readyState == 'interactive') {
      // This timeout is to make sure that IE has time to load
      setTimeout(function () {
        if (document.querySelector('sdds-theme')) return;

        // Used in case a theme element is not rendered
        style.nodeValue = 'body { visibility: visible; }';
      });
    }
  };
}