import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AbacoGridComponent } from '../abaco-grid/abaco-grid.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGridColumn } from 'src/shared/models/interfaces/IgridColumn';
import { IGridTemplate } from 'src/shared/models/interfaces/IgridTemplate';
import { ToastService } from 'src/shared/services/toast.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { NgIconsModule } from '@ng-icons/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InfoNotification } from 'src/shared/utils/notification-types';

@Component({
  selector: 'app-selecao-modal',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgIconsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDividerModule,
    AbacoGridComponent,
    MatIconModule,
    MatPaginatorModule
  ],
  templateUrl: './selecao-modal.component.html',
  styleUrl: './selecao-modal.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SelecaoModalComponent<T> implements AfterViewInit {
  @ViewChild(AbacoGridComponent) gridComponent!: AbacoGridComponent<any>;
  titulo!: string;
  subtitulo!: string;
  gridColumns: IGridColumn[] = [];
  gridTemplates: IGridTemplate[] = [];
  data: T[] = [];
  page!: number;
  pageSize!: number;
  totalItens: number = 0;
  selecaoUnica!: boolean;
  cardStyle: string = 'card-no-max-width';

  constructor(
    public dialogRef: MatDialogRef<SelecaoModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public componentData: any,
    private toastService: ToastService) {
    this.titulo = componentData?.titulo;
    this.subtitulo = componentData?.subtitulo;
    this.gridColumns = componentData?.gridColumns;
    this.gridTemplates = componentData?.gridTemplates;
    this.selecaoUnica = componentData?.selecaoUnica;
    this.page = componentData?.page;
    this.pageSize = componentData?.pageSize;
    this.data = componentData?.data;
    if(componentData.cardStyle){
      this.cardStyle = componentData?.cardStyle;
    }
  }

  ngAfterViewInit(): void {
    this.gridComponent.dataSource.data = this.data;
  }

  sendToParent() {
    if (this.selecaoUnica) {
      return this.gridComponent.selection.selected.length > 1
        ? this.toastService.open(new InfoNotification("Selecione apenas um dos itens para prosseguir"))
        : this.dialogRef.close(this.gridComponent.selection.selected[0]);
    }
    this.dialogRef.close(this.gridComponent.selection.selected);
  }

  close() {
    this.dialogRef.close();
  }

}
