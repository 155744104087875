export function hasValue(value: any) {
    if (typeof value === 'string') {
        return value.trim().length > 0;
    }
    if (typeof value === 'number') {
        return !isNaN(value) && Number.isFinite(value);
    }
    return false;
}

export function toRecord<T extends object>(obj: T): Record<string, unknown> {
    return Object.entries(obj)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, unknown>);
  }

export function toArray<T>(input: T | T[] | null | undefined): T[] {
    return input ? (Array.isArray(input) ? input : [input]) : [];
}