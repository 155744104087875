<div class="tds-container">
    <div class="tds-row">
        <div class="tds-col-xs-12
            tds-col-sm-12
            tds-col-md-12
            tds-col-lg-12
            tds-col-xlg-12
            tds-col-xxlg-12
            tds-col-max-12">
            <tds-card header="Cópia de Pedido" subheader="Solicitar Cópia" body-divider="true">
                <div slot="body" class="card-no-max-width">
                    <div class="tds-row">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3">
                            <tds-text-field [(ngModel)]="fornecedorAntigoSelecionado.nome" ngDefaultControl size="md"
                                label-position="inside" placeholder="Fornecedor">
                                <span slot="tds-label">Fornecedor Antigo</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-hide-xs
                                    tds-hide-sm
                                    tds-hide-md
                                    tds-hide-lg
                                    tds-show-xlg
                                    tds-show-xxlg
                                    tds-show-max
                                    tds-col-xlg-1
                                    tds-col-xxlg-1
                                    tds-col-max-1
                                    tds-u-pt3
                                    ">
                            <tds-button (click)="this.openDialogFornecedor()" variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-show-xs
                        tds-show-sm
                        tds-show-md
                        tds-show-lg
                        tds-hide-xlg
                        tds-hide-xxlg
                        tds-hide-max
                        tds-u-mt3
                        tds-u-mb3
                        ">
                            <tds-button (click)="this.openDialogFornecedor()" fullbleed variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3">
                            <tds-text-field [(ngModel)]="pedidoAntigoSelecionado.id" ngDefaultControl size="md"
                                label-position="inside" placeholder="Pedido">
                                <span slot="tds-label">Pedido Antigo</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-hide-xs
                                    tds-hide-sm
                                    tds-hide-md
                                    tds-hide-lg
                                    tds-show-xlg
                                    tds-show-xxlg
                                    tds-show-max
                                    tds-col-sm-1
                                    tds-col-md-1
                                    tds-col-lg-1
                                    tds-col-xlg-1
                                    tds-col-xxlg-1
                                    tds-col-max-1
                                    tds-u-pt3
                                    ">
                            <tds-button (click)="this.openDialogPedido()" variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                        tds-col-sm-12
                        tds-col-md-12
                        tds-col-lg-12
                        tds-show-xs
                        tds-show-sm
                        tds-show-md
                        tds-show-lg
                        tds-hide-xlg
                        tds-hide-xxlg
                        tds-hide-max
                        tds-u-mt3
                        tds-u-mb3
                        ">
                            <tds-button (click)="this.openDialogPedido()" fullbleed variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3">
                            <tds-text-field [(ngModel)]="fornecedorNovoSelecionado.nome" ngDefaultControl size="md"
                                label-position="inside" placeholder="Fornecedor">
                                <span slot="tds-label">Fornecedor Novo</span>
                            </tds-text-field>
                        </div>
                        <div class="tds-hide-xs
                                    tds-hide-sm
                                    tds-hide-md
                                    tds-hide-lg
                                    tds-show-xlg
                                    tds-show-xxlg
                                    tds-show-max
                                    tds-col-sm-1
                                    tds-col-md-1
                                    tds-col-lg-1
                                    tds-col-xlg-1
                                    tds-col-xxlg-1
                                    tds-col-max-1
                                    tds-u-pt3
                                    ">
                            <tds-button (click)="this.openDialogFornecedor(true)" variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-show-xs
                                    tds-show-sm
                                    tds-show-md
                                    tds-show-lg
                                    tds-hide-xlg
                                    tds-hide-xxlg
                                    tds-hide-max
                                    tds-u-mt3
                                    tds-u-mb3
                        ">
                            <tds-button (click)="this.openDialogFornecedor(true)" fullbleed variant="secondary" size="sm">
                                <tds-icon slot="icon" size="16px" name="arrow_diagonal"></tds-icon>
                            </tds-button>
                        </div>
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-3
                                    tds-col-xxlg-3
                                    tds-col-max-3
                                    tds-u-mt3">
                                    <tds-checkbox class="" [value]="gerarBlocoAgreement">
                                        <span slot="label">Gerar Bloco Agreement</span>
                                    </tds-checkbox>
                        </div>
                       
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <tds-button (click)="this.openDialogFornecedor()" fullbleed variant="primary" text="Copiar Pedido" size="sm"></tds-button>       
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div
                            class="tds-col-max-3 tds-col-xxlg-3 tds-col-xlg-3 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                            <h2 class="tds-card-headline">Pedido Gerado: 1234567</h2>
                        </div>
                        <div
                            class="tds-col-max-9 tds-col-xxlg-9 tds-col-xlg-9 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                            <h2 class="tds-card-headline">
                                EDI
                                <mat-icon class="mat--icon--copy--middle" aria-hidden="false" aria-label="iconCopy"
                                    matTooltip="Copiar EDI" fontIcon="content_copy"
                                    [matTooltipPosition]="ediTooltipDirection"></mat-icon>
                            </h2>
                            <tds-textarea placeholder="Edi Gerado"></tds-textarea>
                        </div>
                    </div>
                </div>
            </tds-card>
        </div>
    </div>
</div>

