import { TiposInputBusca } from "../enums/tiposInputBusca.enum";
import { IInputBusca } from "../models/interfaces/IInputBusca";

export var inputsBuscaFornecedor: IInputBusca[] = [{
    type: TiposInputBusca.TEXT,
    label: "Codigo Fornecedor",
    ngModel: "fornecedor",
    placeholder: "Código do Fornecedor",
    name: "fornecedor",
  }, {
    type: TiposInputBusca.TEXT,
    label: "MF do Fornecedor",
    ngModel: "mf",
    placeholder: "MF do Fornecedor",
    name: "mf",
  }, {
    type: TiposInputBusca.TEXT,
    label: "Nome do Fornecedor",
    ngModel: "nome",
    placeholder: "Nome do Fornecedor",
    name: "nome",
  }];

export var inputsBuscaPedido: IInputBusca[] = [{
    type: TiposInputBusca.TEXT,
    label: "Tipo de Fornecedor",
    ngModel: "tipoFornecedor",
    placeholder: "Tipo de Fornecedor",
    name: "tipoFornecedor",
  }, {
    type: TiposInputBusca.TEXT,
    label: "Id do Fornecedor",
    ngModel: "fornecedorId",
    placeholder: "Id do Fornecedor",
    name: "fornecedorId",
  }, {
    type: TiposInputBusca.TEXT,
    label: "MF do Fornecedor",
    ngModel: "mf",
    placeholder: "MF do Fornecedor",
    name: "mf",
  }, {
    type: TiposInputBusca.TEXT,
    label: "Tipo do Pedido",
    ngModel: "tipoPedido",
    placeholder: "Tipo do Pedido",
    name: "tipoPedido",
  }, {
    type: TiposInputBusca.TEXT,
    label: "Id do Pedido",
    ngModel: "pedidoId",
    placeholder: "Id do Pedido",
    name: "pedidoId",
  }, {
    type: TiposInputBusca.TEXT,
    label: "Pop Id",
    ngModel: "popId",
    placeholder: "Pop Id",
    name: "popId",
  }];