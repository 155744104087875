<div>
  <table mat-table matSort [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}" class="tds-table tds-table--compact" [dataSource]="dataSource"
    matSort>

    <!-- Select All Checkbox Column -->
    <ng-container matColumnDef="select" *ngIf="enableSelectAll">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}" class=" small-checkbox-container">
        <mat-checkbox color="primary" (change)="toggleAllRows($event)" [checked]="isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" class="small-checkbox">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}" class="small-checkbox-container">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="toggleRow(row)"
          [checked]="selection.isSelected(row)" [disabled]="row.disabled == true" class="small-checkbox">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Dynamic Columns -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
      <th mat-header-cell class="tds-table__header-cell " [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}" *matHeaderCellDef mat-sort-header>
        {{ column.label }}</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}">
        <ng-container *ngIf="column.template; else defaultTemplate">
          <ng-template [ngTemplateOutlet]="getTemplate(column.template)"
            [ngTemplateOutletContext]="{ element: element }">
          </ng-template>
        </ng-container>
        <ng-template #defaultTemplate>
          {{ element[column.key] }}
        </ng-template>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions" *ngIf="actions.length > 0">
      <th mat-header-cell *matHeaderCellDef  [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}">Ações</th>
      <td mat-cell *matCellDef="let item"  [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}">
        <div class="mat-flex-button">
          <mat-fab *ngFor="let action of actions" (click)="onAction(action, item)" matTooltip="{{action.label}}">
            <img *ngIf="action.imgSrc" [src]="action.imgSrc" alt="{{ action.label }}">
            <ng-icon class="ng-icon-default-size" name="{{action.ngIconName}}" color="{{action.color}}"
              *ngIf="action.isNgIcon">{{ action.icon }}</ng-icon>
          </mat-fab>
        </div>
      </td>
    </ng-container>

    <!-- Rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="tds-table__row" [ngClass]="{'mat-left': this.gridAlignment == 'left',
        'mat-center': this.gridAlignment == 'center',
        'mat-right': this.gridAlignment == 'right'}"></tr>
  </table>
  <mat-paginator [pageSize]="this.pageSize" [pageIndex]="this.page" [length]="this.totalItens"
    showFirstLastButtons (page)="onPageChange($event)" (pageSizeChange)="onItemsPerPageChange()"></mat-paginator>
</div>