<div class="tds-container tds-u-p0">
    <div class="tds-row">
        <div class="tds-col-xs-12
            tds-col-sm-12
            tds-col-md-12
            tds-col-lg-12
            tds-col-xlg-12
            tds-col-xxlg-12
            tds-col-max-12 tds-no-padding">
            <tds-card header="{{titulo}}" subheader="{{subtitulo}}" body-divider="true">
                <div slot="body" [ngClass]="cardStyle" class="card-with-scroll">
                    <div class="tds-row">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-12
                                    tds-col-xxlg-12
                                    tds-col-max-12">
                            <form [formGroup]="searchForm" class="form-flex">
                                <div *ngFor="let input of inputsBusca">
                                    <div class="tds-u-m2">
                                        <div *ngIf="input.type == tiposInputBusca.TEXT">
                                            <tds-text-field class="inputs-text-box" formControlName="{{input.ngModel}}"
                                                placeholder="{{input.placeholder}}" ngDefaultControl>
                                            </tds-text-field>
                                        </div>
                                        <div *ngIf="input.type == tiposInputBusca.SELECT">
                                            <tds-dropdown #input.id formControlName="{{input.ngModel}}"
                                                mode-variant="secondary" name="{{input.name}}" label="{{input.label}}"
                                                label-position="outside" placeholder="{{input.placeholder}}" size="lg"
                                                open-direction="down">
                                                <tds-dropdown-option
                                                    *ngFor="let option of input.selectOptions; let idx = index"
                                                    value="{{option.codigo}}" filter>
                                                    {{ option.descricao }}
                                                </tds-dropdown-option>
                                            </tds-dropdown>
                                        </div>
                                        <div *ngIf="input.type == tiposInputBusca.AUTOCOMPLETE">

                                            <mat-form-field appearance="fill" class="inputs-text-box">
                                                <mat-label>{{input.label}}</mat-label>
                                                <input placeholder="{{input.placeholder}}" matInput
                                                    [formControlName]="input.ngModel" name="{{input.name}}"
                                                    [matAutocomplete]="auto">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option *ngFor="let option of input.autoCompleteOptions | async"
                                                        [value]="option.codigo">
                                                        {{ option.descricao }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-12
                                    tds-col-xxlg-12
                                    tds-col-max-12
                                    ">
                            <tds-button type="primary" fullbleed text="Pesquisar" size="sm" (click)="search()"
                                class="buttons-find"></tds-button>
                        </div>
                    </div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-12
                                    tds-col-xxlg-12
                                    tds-col-max-12">
                            <app-abaco-grid [data]="this.data" [columns]="this.gridColumns" [enableSelectAll]="true"
                                [gridAlignment]="'left'" [templates]="this.gridTemplates" [filterModalPaginator]="true"
                                [filterSelectAllFunction]="filterSelectAllFunction" [paginationFunction]="fetchData"
                                [totalItens]="this.totalItens" [pageSize]="this.pageSize"
                                [page]="this.page"></app-abaco-grid>
                        </div>
                    </div>
                </div>
                <div slot="actions" [ngClass]="cardStyle">
                    <tds-button type="primary" text="Selecionar" size="sm" (click)="sendToParent()"
                        class="buttons-find"></tds-button>
                    <tds-button type="secondary" text="Fechar" size="sm" (click)="close()"
                        class="buttons-find"></tds-button>
                </div>
            </tds-card>
        </div>
    </div>
</div>