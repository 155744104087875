<div class="tds-container tds-u-p0">
    <div class="tds-row">
        <div class="tds-col-xs-12
            tds-col-sm-12
            tds-col-md-12
            tds-col-lg-12
            tds-col-xlg-12
            tds-col-xxlg-12
            tds-col-max-12 tds-no-padding">
            <tds-card header="{{titulo}}" subheader="{{subtitulo}}" body-divider="true">
                <div slot="body" [ngClass]="cardStyle" class="card-with-scroll">
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-xs-12
                                    tds-col-sm-12
                                    tds-col-md-12
                                    tds-col-lg-12
                                    tds-col-xlg-12
                                    tds-col-xxlg-12
                                    tds-col-max-12">
                            <app-abaco-grid [data]="this.data" [columns]="this.gridColumns" [enableSelectAll]="true"
                                [gridAlignment]="'left'" [templates]="this.gridTemplates" [filterModalPaginator]="true"
                                [totalItens]="this.totalItens" [pageSize]="this.pageSize"
                                [page]="this.page"></app-abaco-grid>
                        </div>
                    </div>
                </div>
                <div slot="actions" [ngClass]="cardStyle">
                    <tds-button type="primary" text="Selecionar" size="sm" (click)="sendToParent()"
                        class="buttons-find"></tds-button>
                    <tds-button type="secondary" text="Fechar" size="sm" (click)="close()"
                        class="buttons-find"></tds-button>
                </div>
            </tds-card>
        </div>
    </div>
</div>