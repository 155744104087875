import { Component, Input, ViewChild, Output, EventEmitter, TemplateRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIconsModule } from '@ng-icons/core';
import { IGridAction } from 'src/shared/models/interfaces/IgridAction';
import { ToastService } from 'src/shared/services/toast.service';
import { ErrorNotification } from 'src/shared/utils/notification-types';
import { Result } from 'src/shared/models/classes/result';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-abaco-grid',
    templateUrl: './abaco-grid.component.html',
    styleUrl: './abaco-grid.component.scss',
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        NgIconsModule
    ]
})
export class AbacoGridComponent<T> implements OnInit {
  @Input() data: T[] = [];
  @Input() columns: { key: string, label: string, template?: string }[] = [];
  @Input() templates: { key: string, value: TemplateRef<any> }[] = [];
  @Input() actions: IGridAction<T>[] = [];
  @Input() enableSelectAll: boolean = false;
  @Input() gridAlignment: string = 'center';
  @Input() filterModalPaginator!: boolean;
  @Input() filterSelectAllFunction: any;
  @Input() paginationFunction!: (pageIndex: number, pageSize: number) => Observable<Result<T>>;
  @Input() page!: number | undefined;
  @Input() pageSize!: number | undefined;
  @Input() totalItens!: number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource<T>();
  paginatorIntl: MatPaginatorIntl = new MatPaginatorIntl();
  selection = new SelectionModel<T>(true, []);
  @Output() selectionChange = new EventEmitter<T[]>();
  @Output() actionTriggered = new EventEmitter<{ action: IGridAction<T>; item: T }>();
  displayedColumns: string[] = [];
  pageSizeOptions: [] = [];
  defaultPageSizeOptions = [10, 25, 50, 100, 200, 500, 1000];

  constructor(private toast: ToastService, private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.initalizeDisplayedColumns();
  }

  updatePaginatorAndData(totalItens?: number, pageIndex?: number, pageSize?: number, data?: T[]) {
    pageIndex = pageIndex ? pageIndex - 1 : 0;
    if (this.paginator) {
      this.totalItens = totalItens ?? 0;
      this.page = pageIndex ?? this.page;
      this.pageSize = pageSize ?? this.pageSize;
      this.paginator.pageIndex = this.page ?? 0; // Force paginator update
      this.paginator.pageSize = this.pageSize ?? 0; // Force paginator update
      this.paginator.length = this.totalItens; // Force paginator update
      if (this.data) {
        this.dataSource.data = [...(data || [])];
      }
      this.changeDetectorRef.detectChanges();

    }
  }

  updateSelection() {
    // Retain selections that are still valid in the new data set
    const selectedIds = this.selection.selected.map((item: any) => item['id']);
    this.selection.clear(); // Clear the selection first

    // Re-select items that are still present in the new data
    this.dataSource.data.forEach((item: any) => {
      if (selectedIds.includes(item['id'])) {
        this.selection.select(item);
      }
    });
  }

  isAllSelected(): boolean {
    var currentPageData = this.dataSource.filteredData;
    if (this.filterSelectAllFunction) {
      currentPageData = currentPageData.filter(this.filterSelectAllFunction);
      return currentPageData.length > 0 && currentPageData.every(item => this.selection.isSelected(item));
    }
    return currentPageData.length > 0 && currentPageData.every(item => this.selection.isSelected(item));
  }

  initalizeDisplayedColumns() {
    this.displayedColumns = this.enableSelectAll ? ['select'] : [];
    this.displayedColumns.push(...this.columns.map(column => column.key));
    if (this.actions.length > 0) {
      this.displayedColumns.push('actions');
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(event: any) {
    const currentPageData = this.getFilteredData(); // Get currently displayed data
    this.dataSource.filteredData = currentPageData;
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      if (this.filterSelectAllFunction) {
        this.dataSource.filteredData.filter(this.filterSelectAllFunction).forEach(row => this.selection.select(row));
      }
      else {
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
      }
    }
    this.emitSelection();
  }

  toggleRow(row: T) {
    this.selection.toggle(row);
    this.emitSelection();
  }

  toggleRowSet(rows: T[]) {
    this.selection.setSelection(...rows);
    this.emitSelection();
  }

  clearSelection() {
    this.selection.clear();
    this.emitSelection();
  }

  /** Emit the current selection to the parent component */
  emitSelection() {
    this.selectionChange.emit(this.selection.selected);
  }

  onPageChange(pageEvent: PageEvent) {
    this.data = [];
    this.dataSource.data = [...[]];
    var pageIndexForPagination = 0;
    if (pageEvent.pageIndex == 0) {
      pageIndexForPagination = 1;
    }
    else {
      pageIndexForPagination = pageEvent.pageIndex + 1;
    }
    this.clearSelection(); // Reset selection when page changes
    if (this.paginationFunction) {
      this.paginationFunction(pageIndexForPagination, pageEvent.pageSize).subscribe({
        next: (result: Result<T>) => {
          this.dataSource.data = [...[]];
          this.totalItens = result.totalRecords ?? 0; // Update total items for pagination
          this.data = [];
          if (Array.isArray(result.content)) {
            this.data.push(...result.content);
          } else if (result.content !== null && result.content !== undefined) {
            this.data.push(result.content);
          }
          this.page = result.page;
          this.pageSize = result.pageSize;
          this.updatePaginatorAndData(result.totalRecords, result.page, result.pageSize, this.data); // Ensure paginator updates
          this.initalizeDisplayedColumns();
        },
        error: (error: Result<T>) => {
          this.toast.open(new ErrorNotification(`Erro ao consultar registros: ${error?.messages?.join(", ")}`, "Consultar", () => this.onPageChange(pageEvent)));
        }
      });
    }
  }

  onItemsPerPageChange() {
    this.clearSelection(); // Reset selection when items per page changes
  }

  getFilteredData(): T[] {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return this.dataSource.data.slice(startIndex, startIndex + this.paginator.pageSize);
  }

  onAction(action: IGridAction<T>, item: T): void {
    action.action(item);
    this.actionTriggered.emit({ action, item });
  }

  getTemplate(templateName: string): TemplateRef<any> | null {
    var template = this.templates.find((template) => template.key == templateName);
    return template ? template.value : null;
  }
}


