// snackbar.service.ts
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Notification, SuccessNotification, ErrorNotification, InfoNotification, WarningNotification } from '../utils/notification-types';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private isSnackbarOpen = false;
  private currentSnackbarRef: any;

  constructor(private snackBar: MatSnackBar) {}

  private getConfig(notification: Notification, duration: number): MatSnackBarConfig {
    const config: MatSnackBarConfig = {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: this.getPanelClass(notification),
    };

    return config;
  }

  private getPanelClass(notification: Notification): string | string[] {

    switch (true) {
      case notification instanceof SuccessNotification:
        return 'success-snackbar';
      case notification instanceof ErrorNotification:
        return 'error-snackbar';
      case notification instanceof InfoNotification:
        return 'info-snackbar';
      case notification instanceof WarningNotification:
        return 'warning-snackbar';
      default:
        return '';
    }
  }

  open(notification: Notification, duration: number = 5000) {

    if (this.isSnackbarOpen) {
      this.currentSnackbarRef.dismiss();
    }

    const config = this.getConfig(notification, duration);
    this.currentSnackbarRef = this.snackBar.open(notification.message, notification.actionLabel, config);

    this.currentSnackbarRef.afterDismissed().subscribe(() => {
      this.isSnackbarOpen = false;
    });

    this.isSnackbarOpen = true;
  }

}
