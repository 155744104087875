import * as i0 from '@angular/core';
import { Optional, SkipSelf, InjectionToken, inject, Component, ChangeDetectionStrategy, Input, HostBinding, NgModule, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
function provideIcons(icons) {
  return [{
    provide: NgIconsToken,
    useFactory: parentIcons => ({
      ...parentIcons?.reduce((acc, icons) => ({
        ...acc,
        ...icons
      }), {}),
      ...icons
    }),
    deps: [[NgIconsToken, new Optional(), new SkipSelf()]],
    multi: true
  }];
}
const NgIconsToken = new InjectionToken('Icons Token');

/**
 * Hyphenated to lowerCamelCase
 */
function toPropertyName(str) {
  return str.replace(/([^a-zA-Z0-9])+(.)?/g, (_, __, chr) => chr ? chr.toUpperCase() : '').replace(/[^a-zA-Z\d]/g, '').replace(/^([A-Z])/, m => m.toLowerCase());
}
class NgIconComponent {
  constructor() {
    this._size = '1em';
    this.sanitizer = inject(DomSanitizer);
    this.icons = inject(NgIconsToken);
  }
  /** Define the name of the icon to display */
  set name(name) {
    name = toPropertyName(name);
    for (const icons of [...this.icons].reverse()) {
      if (icons[name]) {
        // insert the SVG into the template
        this.template = this.sanitizer.bypassSecurityTrustHtml(icons[name]);
        return;
      }
    }
    // if there is no icon with this name warn the user as they probably forgot to import it
    console.warn(`No icon named ${name} was found. You may need to import it using the withIcons function.`);
  }
  /** Define the size of the icon */
  set size(size) {
    // if the size only contains numbers, assume it is in pixels
    this._size = coerceCssPixelValue(size);
  }
  get size() {
    return this._size;
  }
  static {
    this.ɵfac = function NgIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgIconComponent,
      selectors: [["ng-icon"]],
      hostVars: 7,
      hostBindings: function NgIconComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("innerHTML", ctx.template, i0.ɵɵsanitizeHtml);
          i0.ɵɵstyleProp("--ng-icon__size", ctx.size)("--ng-icon__stroke-width", ctx.strokeWidth)("color", ctx.color);
        }
      },
      inputs: {
        name: "name",
        size: "size",
        strokeWidth: "strokeWidth",
        color: "color"
      },
      decls: 0,
      vars: 0,
      template: function NgIconComponent_Template(rf, ctx) {},
      styles: ["[_nghost-%COMP%]{display:inline-block;width:var(--ng-icon__size);height:var(--ng-icon__size)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgIconComponent, [{
    type: Component,
    args: [{
      selector: 'ng-icon',
      template: '',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:inline-block;width:var(--ng-icon__size);height:var(--ng-icon__size)}\n"]
    }]
  }], null, {
    name: [{
      type: Input
    }],
    template: [{
      type: HostBinding,
      args: ['innerHTML']
    }],
    size: [{
      type: HostBinding,
      args: ['style.--ng-icon__size']
    }, {
      type: Input
    }],
    strokeWidth: [{
      type: HostBinding,
      args: ['style.--ng-icon__stroke-width']
    }, {
      type: Input
    }],
    color: [{
      type: HostBinding,
      args: ['style.color']
    }, {
      type: Input
    }]
  });
})();
function coerceCssPixelValue(value) {
  return value == null ? '' : /^\d+$/.test(value) ? `${value}px` : value;
}
class NgIconsModule {
  constructor(icons) {
    if (Object.keys(icons).length === 0) {
      throw new Error('No icons have been provided. Ensure to include some icons by importing them using NgIconsModule.withIcons({ ... }).');
    }
  }
  /**
   * Define the icons that will be included in the application. This allows unused icons to
   * be tree-shaken away to reduce bundle size
   * @param icons The object containing the required icons
   */
  static withIcons(icons) {
    return {
      ngModule: NgIconsModule,
      providers: provideIcons(icons)
    };
  }
  static {
    this.ɵfac = function NgIconsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgIconsModule)(i0.ɵɵinject(NgIconsToken));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgIconsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgIconsModule, [{
    type: NgModule,
    args: [{
      imports: [NgIconComponent],
      exports: [NgIconComponent]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NgIconsToken]
      }]
    }];
  }, null);
})();
// This is a temporary workaround for ng-packagr issue #2398
class NG_ICON_DIRECTIVES {
  static {
    this.ɵfac = function NG_ICON_DIRECTIVES_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NG_ICON_DIRECTIVES)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NG_ICON_DIRECTIVES
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NG_ICON_DIRECTIVES, [{
    type: NgModule,
    args: [{
      imports: [NgIconComponent],
      exports: [NgIconComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NG_ICON_DIRECTIVES, NgIconComponent, NgIconsModule, NgIconsToken, provideIcons };
