import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Fornecedor } from '../models/classes/fornecedor';
import { BaseService } from './base.service';
import { BASE_URL } from '../tokens/baseUrl.token';

@Injectable({
  providedIn: 'root'
})
export class FornecedorService extends BaseService<Fornecedor> {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, `${baseUrl}/Fornecedor`); 
  }
}






