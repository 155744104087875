import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular-17';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [CommonModule, TegelModule, RouterOutlet, RouterLink],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppComponent {
  mobileNavOpen = false;

  toggleMobileSideMenu() {
    this.mobileNavOpen = !this.mobileNavOpen;
  }

}

