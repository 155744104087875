import { IGridColumn } from '../models/interfaces/IgridColumn';

export var columnsFornecedorGrid: IGridColumn[] = [
    { key: 'fornecedorId', label: 'Fornecedor Id' },
    { key: 'mf', label: 'MF' },
    { key: 'nome', label: 'Nome' },
];

export var columnsPedidoGrid: IGridColumn[] = [
    { key: 'pedidoId', label: 'Pedido Id' },
    { key: 'popId', label: 'Pop Id' },
    { key: 'cm', label: 'CM' },
    { key: 'chassi', label: 'Chassi' },
    { key: 'comentario', label: 'Comentario' }
];